import "./Home.scss";

const Home = () => {
  return (
    <div id="home" className="home">
      <div className="home-overlay"></div>
      <div className="container home-container">
        <div className="home-container-wrapper">
          <div className="home-container-wrapper-banner">
            <div className="home-container-wrapper-banner-text1">
              Best Solution & Creative
            </div>
            <div className="home-container-wrapper-banner-text2">
              Grow your business now
            </div>
            <div className="home-container-wrapper-banner-text3">
              Together we are going to help you determining a goal, choosing
              your tools and developing your businesses to make it Distinct.
            </div>
            <div className="home-container-wrapper-banner-buttons">
              <a href="#service">
                <span
                  style={{
                    backgroundColor: "transparent",
                    borderColor: "white",
                  }}
                  className="home-container-wrapper-banner-buttons-btn1 btn-style"
                >
                  Get Started
                </span>
              </a>
              <a href="#about">
                <span className="btn-style">Read More</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Home;
