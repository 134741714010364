import { useRef, useState } from "react";
import "./ContactUs.scss";
import emailjs from "@emailjs/browser";

const initialState = {
  user_name: "",
  user_email: "",
  phone: "",
  subject: "",
  message: "",
};

const ContactUs = () => {
  const form = useRef();
  const [formData, setFormData] = useState(initialState);
  const [btnDisabled, setBtnDisabled] = useState(true);

  const isValidate = (data) => {
    if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(data.user_email))
      return false;
    if (!/^[6-9]\d{9}$/.test(data.phone)) return false;
    if (!/^[a-zA-Z ]{2,30}$/.test(data.user_name)) return false;
    // if (/^[a-zA-Z0-9-_%&*$#]+$/.test(data.message)) return false;

    // if (/^[a-zA-Z0-9-]+$/.test(data.message)) return false;

    return true;
  };

  const sendEmail = (e) => {
    e.preventDefault();
    if (isValidate(formData)) {
      emailjs
        .sendForm(
          "service_z1qyxs8",
          "template_zljreqg",
          form.current,
          "G6RlQUax16rA4gcOl"
        )
        .then((result) => {
          setBtnDisabled(true);
          setFormData(initialState);
          console.log("email sent");
          // show the user a success message
        })
        .catch((error) => {
          setBtnDisabled(false);
          // show the user an error
          console.error(error, "email error");
        });
    } else setBtnDisabled(true);
  };
  const handleChange = (e) => {
    setBtnDisabled(false);
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  return (
    <div id="contact" className="contact">
      <div className="container contact-container">
        <div className="contact-container-wrapper">
          <div className="contact-container-wrapper-typography">
            <div className="contact-container-wrapper-typography-left">
              <h2 className="contact-container-wrapper-typography-left-head">
                leave your message
              </h2>
              <h3 className="contact-container-wrapper-typography-left-subhead">
                get in touch
              </h3>
            </div>
            {/* <p className="contact-container-wrapper-typography-right">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
              eiusm tempor incididunt ut labore et dolore magna aliqua. Ut enim
              ad minim veniam.
            </p> */}
          </div>
          <form ref={form} className="contact-container-wrapper-form">
            <div className="contact-container-wrapper-form-main">
              <input
                value={formData.user_name}
                name="user_name"
                type="text"
                placeholder="Name*"
                onChange={handleChange}
                autoComplete="off"
              />
              <input
                value={formData.user_email}
                name="user_email"
                type="email"
                placeholder="Email*"
                onChange={handleChange}
              />
              <input
                value={formData.subject}
                name="subject"
                type="text"
                placeholder="Subject*"
                onChange={handleChange}
                autoComplete="off"
              />
              <input
                value={formData.phone}
                name="phone"
                type="tel"
                placeholder="Phone Number*"
                onChange={handleChange}
                autoComplete="off"
              />
            </div>
            <div className="contact-container-wrapper-form-optional">
              <textarea
                value={formData.message}
                placeholder="Leave Your Message Here...*"
                name="message"
                onChange={handleChange}
                autoComplete="off"
              />
            </div>
            <button
              disabled={btnDisabled}
              onClick={sendEmail}
              className="contact-container-wrapper-form-button"
            >
              <div>Send Your Message</div>
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};
export default ContactUs;
