import "./About.scss";
import About1 from "./about1.jpg";
import About2 from "./about2.jpg";

const Contact = ({ headRef }) => {
  return (
    <div id="about" ref={headRef} className="contact">
      {/* <div className="container"> */}
      <div className="contact-box1">
        <div className="container contact-box1-container">
          <div className="contact-box1-container-wrapper">
            <div className="contact-box1-container-wrapper-left">
              <div className="contact-box1-container-wrapper-left-content">
                <img src={About1} alt="about" />
              </div>
            </div>
            <div className="contact-box1-container-wrapper-right">
              <div className="contact-box1-container-wrapper-right-headline">
                <h2>WHO WE ARE</h2>
                <h3>PROVIDE AWESOME AND BEST BUSINESS SOLUTIONS</h3>
              </div>
              <p className="contact-box1-container-wrapper-right-about">
                We are a dynamic and innovative technology company dedicated to
                transforming businesses through cutting-edge solutions. With a
                passion for excellence and a commitment to delivering
                exceptional results, we pride ourselves on being a leading
                provider of IT services and consultancy.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="contact-box2">
        <div className="container contact-box2-container">
          <div className="contact-box2-container-wrapper">
            <div className="contact-box2-container-wrapper-left">
              <div className="contact-box2-container-wrapper-left-headline">
                <h3>WHAT WE PROVIDE YOU</h3>
              </div>
              <p className="contact-box2-container-wrapper-left-about">
                Our company values are built on integrity, transparency, and
                collaboration. We believe in fostering strong relationships with
                our clients, working closely with them to understand their goals
                and deliver solutions that exceed their expectations. Customer
                satisfaction is at the core of everything we do, and we strive
                to build long-term partnerships based on trust and mutual
                success.
              </p>
              <ul className="contact-box2-container-wrapper-left-list">
                <li>
                  <h4>
                    <span></span>
                    Free Delivery
                  </h4>
                </li>
                <li>
                  <h4>
                    <span></span>
                    Awesome Unique Design
                  </h4>
                </li>
                <li>
                  <h4>
                    <span></span>
                    Performance & Quality
                  </h4>
                </li>
                <li>
                  <h4>
                    <span></span>
                    Super Fast
                  </h4>
                </li>
                {/* <li>
                  <h4>
                    <span></span>
                    Valid HTML5/CSS3
                  </h4>
                </li> */}
                {/* <li>
                  <h4>
                    <span></span>
                    Latest Bootstrap
                  </h4>
                </li> */}
                <li>
                  <h4>
                    <span></span>
                    Friendly Support
                  </h4>
                </li>
              </ul>
            </div>
            <div className="contact-box2-container-wrapper-right">
              <div className="contact-box2-container-wrapper-right-content">
                <img src={About2} alt="about" />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* </div> */}
    </div>
  );
};

export default Contact;
