import { useEffect, useState } from "react";
import "./Header.scss";
import Menu from "./menu.svg";

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const listenScrollEvent = (event) => {
    if (window.scrollY > 20) {
      document.getElementsByClassName("navbar")[0].style.backgroundColor =
        "white";
      document.getElementsByClassName("navbar-container-logo")[0].style.color =
        "#F39C12";
      document
        .querySelectorAll(".navbar-container-collapse-navlinks-tab-span")
        .forEach((ele) => {
          ele.style.color = "black";
        });
    } else if (window.scrollY < 20) {
      document.getElementsByClassName("navbar")[0].style.backgroundColor =
        "transparent";
      document.getElementsByClassName("navbar-container-logo")[0].style.color =
        "white";
      document
        .querySelectorAll(".navbar-container-collapse-navlinks-tab-span")
        .forEach((ele) => {
          ele.style.color = "white";
        });
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);

    return () => window.removeEventListener("scroll", listenScrollEvent);
  }, []);
  return (
    <div className="navbar fixed-top">
      <div className="container navbar-container">
        <span className="navbar-container-logo">NewLife IT</span>
        <div
          className="navbar-container-menu"
          onClick={() => setIsMenuOpen(!isMenuOpen)}
        >
          <img
            src={Menu}
            alt="menu"
            width={40}
            height={40}
            style={{ color: "black" }}
          />
        </div>
        {isMenuOpen && (
          <div className="navbar-container-mobilemenu">
            <ul className="container navbar-container-mobilemenu-navlinks">
              <a onClick={() => setIsMenuOpen(false)} href="#home">
                <li className="navbar-container-mobilemenu-navlinks-tab">
                  <span>home</span>
                </li>
              </a>
              <a onClick={() => setIsMenuOpen(false)} href="#about">
                <li className="navbar-container-mobilemenu-navlinks-tab">
                  <span>about</span>
                </li>
              </a>
              <a onClick={() => setIsMenuOpen(false)} href="#service">
                <li className="navbar-container-mobilemenu-navlinks-tab">
                  <span>services</span>
                </li>
              </a>
              {/* <a onClick={() => setIsMenuOpen(false)} href="#team">
                <li className="navbar-container-mobilemenu-navlinks-tab">
                  <span>team</span>
                </li>
              </a> */}
              {/* <a onClick={() => setIsMenuOpen(false)} href="#review">
                <li className="navbar-container-mobilemenu-navlinks-tab">
                  <span>client</span>
                </li>
              </a> */}
              <a onClick={() => setIsMenuOpen(false)} href="#contact">
                <li className="navbar-container-mobilemenu-navlinks-tab">
                  <span>contact</span>
                </li>
              </a>
            </ul>
          </div>
        )}
        <div className="navbar-container-collapse">
          <ul className="navbar-container-collapse-navlinks">
            <a href="#home">
              <li className="navbar-container-collapse-navlinks-tab">
                <span className="navbar-container-collapse-navlinks-tab-span">
                  home
                </span>
              </li>
            </a>
            <a href="#about">
              <li className="navbar-container-collapse-navlinks-tab">
                <span className="navbar-container-collapse-navlinks-tab-span">
                  about
                </span>
              </li>
            </a>
            <a href="#service">
              <li className="navbar-container-collapse-navlinks-tab">
                <span className="navbar-container-collapse-navlinks-tab-span">
                  services
                </span>
              </li>
            </a>
            {/* <a href="#team">
              <li className="navbar-container-collapse-navlinks-tab">
                <span className="navbar-container-collapse-navlinks-tab-span">
                  team
                </span>
              </li>
            </a> */}
            {/* <a href="#review">
              <li className="navbar-container-collapse-navlinks-tab">
                <span className="navbar-container-collapse-navlinks-tab-span">
                  client
                </span>
              </li>
            </a> */}
            <a href="#contact">
              <li className="navbar-container-collapse-navlinks-tab">
                <span className="navbar-container-collapse-navlinks-tab-span">
                  contact
                </span>
              </li>
            </a>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Header;
