import "./Services.scss";
const Services = () => {
  return (
    <div id="service" className="servics">
      <div className="container servics-container">
        <div className="service-block">
          <div className="service-header">
            <div className="title">
              <h2 className="top-handline-title"> WHAT WE PROVIDE </h2>
              <h3 className="handline-title"> BEST &amp; IMPORTANT SERVICES</h3>
            </div>
            <div className="header-description">
              <p className="description">
                We offers a comprehensive range of services designed to empower
                businesses with technology-driven solutions.
              </p>
            </div>
          </div>
          <div className="services">
            <div className="service">
              <span className="icon">
                <i class="fa fa-television" aria-hidden="true"></i>
              </span>
              <div className="service-content">
                <div className="service-heading">Web development</div>
                <ul className="service-description">
                  {/* <hr className="service-seperator" /> */}
                  <li>
                    <span></span>
                    <div>Custom website design and development</div>
                  </li>
                  <li>
                    <span></span>
                    <div></div>E-commerce development
                  </li>
                  <li>
                    <span></span>
                    <div>Content management systems (CMS) development</div>
                  </li>
                  <li>
                    <span></span>
                    <div>Responsive web design</div>
                  </li>
                  <li>
                    <span></span>
                    <div>Website maintenance and support</div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="service">
              <span className="icon">
                <i class="fa fa-television" aria-hidden="true"></i>
              </span>
              <div className="service-content">
                <div className="service-heading">Data Science</div>
                <ul className="service-description">
                  {/* <hr className="service-seperator" /> */}
                  <li>
                    <span></span>
                    <div>Data analysis and modeling</div>
                  </li>
                  <li>
                    <span></span>
                    <div>Predictive analytics</div>
                  </li>
                  <li>
                    <span></span>
                    <div>Machine learning solutions</div>
                  </li>
                  <li>
                    <span></span>
                    <div>Natural language processing</div>
                  </li>
                  <li>
                    <span></span>
                    <div>Data visualization</div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="service">
              <span className="icon">
                <i class="fa fa-television" aria-hidden="true"></i>
              </span>
              <div className="service-content">
                <div className="service-heading">Data Analytics</div>
                <ul className="service-description">
                  <li>
                    <span></span> <div>Data mining and extraction</div>
                  </li>
                  <li>
                    <span></span> <div>Data cleansing and preparation</div>
                  </li>
                  <li>
                    <span></span>
                    <div>Statistical analysis and reporting</div>
                  </li>
                  <li>
                    <span></span>
                    <div>Business intelligence solutions</div>
                  </li>
                  <li>
                    <span></span>
                    <div>Data-driven decision making</div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="service">
              <span className="icon">
                <i class="fa fa-television" aria-hidden="true"></i>
              </span>
              <div className="service-content">
                <div className="service-heading">Data Engineering</div>
                <ul className="service-description">
                  {/* <hr className="service-seperator" /> */}
                  <li>
                    <span></span>Data warehousing and architecture design
                  </li>
                  <li>
                    <span></span>
                    Data integration and ETL (Extract, Transform, Load)
                    processes
                  </li>
                  <li>
                    <span></span>Data pipeline development
                  </li>
                  <li>
                    <span></span>Data governance and security
                  </li>
                  <li>
                    <span></span>Cloud-based data solutions
                  </li>
                </ul>
              </div>
            </div>
            <div className="service">
              <span className="icon">
                <i class="fa fa-television" aria-hidden="true"></i>
              </span>
              <div className="service-content">
                <div className="service-heading">Mobile Development</div>
                <ul className="service-description">
                  {/* <hr className="service-seperator" /> */}
                  <li>
                    <span></span>Native iOS and Android app development
                  </li>
                  <li>
                    <span></span>Cross-platform app development
                  </li>
                  <li>
                    <span></span>Mobile UI/UX design
                  </li>
                  <li>
                    <span></span>App testing and quality assurance
                  </li>
                  <li>
                    <span></span>App maintenance and support
                  </li>
                </ul>
              </div>
            </div>
            <div className="service">
              <span className="icon">
                <i class="fa fa-television" aria-hidden="true"></i>
              </span>
              <div className="service-content">
                <div className="service-heading">Software Development</div>
                <ul className="service-description">
                  {/* <hr className="service-seperator" /> */}
                  <li>
                    <span></span>Custom software development
                  </li>
                  <li>
                    <span></span>Enterprise software solutions
                  </li>
                  <li>
                    <span></span>Application integration and migration
                  </li>
                  <li>
                    <span></span>Software testing and quality assurance
                  </li>
                  <li>
                    <span></span>Software maintenance and support
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
