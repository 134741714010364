import { useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import Contact from "./components/About/About";
import ContactUs from "./components/ContactUs/ContactUs";
import Footer from "./components/Footer/Footer";
import Header from "./components/Header/Header";
import Home from "./components/Home/Home";
import Reviews from "./components/Reviews/Reviews";
import Services from "./components/Services/Services";
import Team from "./components/Team/Team";

function App() {
  const headRef = useRef();
  // useEffect(() => {
  //   const options = {
  //     root: null,
  //     // rootMargin: "0px",
  //     threshold: 0.1,
  //   };

  //   const handleIntersection = (entries) => {
  //     entries.forEach((entry) => {
  //       const intersecting = entry.isIntersecting;
  //       console.log(intersecting, entry);
  //       headRef.current.style.backgroundColor = intersecting
  //         ? "whitesmoke"
  //         : "none";
  //       // entry.target.style.backgroundColor = intersecting ? "blue" : "orange";
  //     });
  //   };
  //   const observer = new IntersectionObserver(handleIntersection, options);
  //   observer.observe(headRef.current);

  //   // Clean up the observer
  //   return () => {
  //     observer.disconnect();
  //   };
  // }, []);

  return (
    <>
      <Header />
      <Home />
      <Contact headRef={headRef} />
      <Services />
      {/* <Team /> */}
      {/* <Reviews /> */}
      <ContactUs />
      <Footer />
    </>
  );
}

export default App;
