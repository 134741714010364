import "./Footer.scss";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container footer-container">
        <div className="footer-container-wrapper">
          <div className="footer-container-wrapper-about">
            <h2 className="footer-container-wrapper-about-head">
              NewLife IT Solutions
            </h2>
            <p className="footer-container-wrapper-about-content">
              Together We Are Going To Help You Determining A Goal, Choosing
              Your Tools And Developing Your Businesses To Make It Distinct.
            </p>
            {/* <ul className="footer-container-wrapper-about-socials">
              <li>
                <a href="#">
                  <i class="fa fa-facebook" aria-hidden="true" />
                </a>
              </li>
              <li>
                <a href="#">
                  <i class="fa fa-twitter" aria-hidden="true" />
                </a>
              </li>
              <li>
                <a href="#">
                  <i class="fa fa-instagram" aria-hidden="true" />
                </a>
              </li>
            </ul> */}
          </div>
          <div className="footer-container-wrapper-contact">
            <h4 className="footer-container-wrapper-contact-head">
              contact info
            </h4>
            {/* <div className="footer-container-wrapper-contact-info">
              <i class="fa fa-location-arrow" aria-hidden="true" />
              <div className="footer-container-wrapper-contact-info-box">
                <p>US - Los Angeles</p>
                <p>15 Street Name Here</p>
              </div>
            </div> */}
            <div className="footer-container-wrapper-contact-info">
              <i class="fa fa-envelope-o" aria-hidden="true" />
              <div className="footer-container-wrapper-contact-info-box">
                <p>solutionnewlifeit@gmail.com</p>
              </div>
            </div>
            <div className="footer-container-wrapper-contact-info">
              <i class="fa fa-phone" aria-hidden="true" />
              <div className="footer-container-wrapper-contact-info-box">
                <p>+91 8107649476</p>
                <p>+91 8209522811</p>
              </div>
            </div>
          </div>
        </div>
        <div className="copyright">
          @ NewLife IT Solutions 2023. All Rights Reserved.{" "}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
